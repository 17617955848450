import React from 'react'
import { useRecoilState } from 'recoil'
import { ModalBox, RangeSlider } from '.'
import { taskSettingsState } from '../_state'
import { Button, Typography, Stack } from '@mui/material'
import StartIcon from '@mui/icons-material/Start'
import { useTranslation } from 'react-i18next'

export const BedExtensionSlider = () => {
    const { t } = useTranslation(['fields'])
    const [bedExtensionModalOpen, setBedExtensionModalOpen] = React.useState(false)
    const [taskSettings, setTaskSettings] = useRecoilState(taskSettingsState)

    return (
        <>
            <Button
                variant='contained'
                onClick={() => setBedExtensionModalOpen(true)}
                sx={{
                    mr: 0.5,
                    textTransform: 'none',
                    p: 1,
                }}
                size={'large'}
                fullWidth
            >
                <Stack>
                    <Typography>{t('fields:BedExtensionSlider.btnText')}</Typography>
                    <Stack direction='row' justifyContent='center'>
                        <StartIcon />
                        {taskSettings.bed_extension}m
                    </Stack>
                </Stack>
            </Button>
            <ModalBox
                title={t('fields:BedExtensionSlider.title')}
                open={bedExtensionModalOpen}
                handleDialogClose={() => setBedExtensionModalOpen(!bedExtensionModalOpen)}
            >
                <RangeSlider
                    value={taskSettings.bed_extension}
                    unit={t('fields:BedExtensionSlider.unit')}
                    step={0.01}
                    min={0.0}
                    max={5.0}
                    handleChange={(newValue: number) => {
                        setTaskSettings((prev) => ({
                            ...prev,
                            bed_extension: newValue,
                        }))
                    }}
                />
            </ModalBox>
        </>
    )
}
