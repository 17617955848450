import { useEffect, useCallback, useRef, useState } from 'react'
import {
    h2lFieldJsonAtom,
    mapAtom,
    taskAtom,
    drawSettingsAtom,
    taskSettingsState,
    selectedBedIDsAtom,
} from '../_state'
import { useRecoilValue } from 'recoil'
import config from '../config.json'
import { useAlertActions } from '../_actions'
import {
    zoomMapLayer,
    addFeaturesToMap,
    addStyleFromGeoJSON,
    removeFeaturesFromMap,
    useBedIDsSelector,
} from '../_helpers'
import { H2LFieldJson, BedGeoJSON, TaskSettings } from '../types'
import { useLocation } from 'react-router-dom'
import { ListItemText, Stack, Divider } from '@mui/material'
import SpaIcon from '@mui/icons-material/Spa'
import { InfoWindow } from '@react-google-maps/api'
import { createPUPMarker } from '../_components/icons/LocationPin'

const arrowSymbol = () => {
    return {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeColor: 'white',
        strokeWeight: 1.0,
        strokeOpacity: 1.0,
        fillOpacity: 1.0,
        fillColor: 'green',
        scale: 4,
    }
}

const createArrow = (map: google.maps.Map) => {
    return new google.maps.Polyline({
        path: [].map((coords) => ({
            lat: coords[1],
            lng: coords[0],
        })),
        strokeOpacity: 0,
        icons: [
            {
                icon: arrowSymbol(),
                offset: '0%',
            },
        ],
        zIndex: 5,
        map,
    })
}

const createFlag = (map: google.maps.Map) => {
    return new google.maps.marker.AdvancedMarkerElement({
        map: map,
        content: new DOMParser().parseFromString(
            '<svg width="50" height="50" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M7.235 7.219v8.777h1.17v-2.34h7.607V7.218Zm8.192 4.096h-1.755v1.755h-1.756v-1.755h-1.755v1.755H8.406v-1.755h1.755V9.559H8.406V7.804h1.755v1.755h1.755V7.804h1.756v1.755h1.755zm-3.51-1.756v1.756h1.755V9.559Z" style="stroke-width:.585138"/><path style="fill:#fff;fill-opacity:1;stroke-width:.0585138;paint-order:markers fill stroke" d="M8.406 7.804h1.755v1.755H8.406zm3.51 0h1.755v1.755h-1.755z"/><path style="fill:#fff;fill-opacity:1;stroke-width:.0585138;paint-order:markers fill stroke" d="M10.161 9.559h1.755v1.755h-1.755zm3.511 0h1.755v1.755h-1.755zm-1.756 1.756h1.755v1.755h-1.755zm-3.51 0h1.755v1.755H8.406z"/></svg>',
            'image/svg+xml',
        ).documentElement,
    })
}

const createTaskLine = (coordinates: google.maps.LatLng[], map: google.maps.Map, name: string) => {
    return new window.google.maps.Polyline({
        strokeColor: name === 'turn' ? 'orange' : 'white',
        strokeWeight: config.drawing.task.STROKE_WEIGHT,
        clickable: false,
        path: coordinates,
        icons:
            name === 'turn'
                ? []
                : [
                      {
                          icon: {
                              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                              strokeColor: config.drawing.task_arrows.STROKE_COLOR,
                              fillColor: config.drawing.task_arrows.FILL_COLOR,
                              fillOpacity: config.drawing.task_arrows.FILL_OPACITY,
                              strokeWeight: config.drawing.task_arrows.STROKE_WEIGHT,
                              scale: config.drawing.task_arrows.SCALE,
                          },
                          repeat: '350px',
                          offset: '0%',
                      },
                  ],
        map,
        zIndex: config.drawing.task.Z_INDEX,
    })
}

const calculateBeginAndEndArrows = (
    field: H2LFieldJson,
    filteredBeds: BedGeoJSON[],
    taskSettings: TaskSettings,
): { begin: { lat: number; lng: number }[]; end: { lat: number; lng: number }[] } => {
    // Extract the row numbers from the filtered beds
    const rowNumbers = filteredBeds.map((bed) => bed.properties.row_number)

    // Find the minimum and maximum row numbers
    const minRow = Math.min(...rowNumbers)
    const maxRow = Math.max(...rowNumbers)

    // Create a set of unique row numbers
    const rowNumbersSet = new Set(rowNumbers)

    // Check if the number of unique row numbers is even
    const isEvenRowCount = rowNumbersSet.size % 2 === 0

    // Check if the difference between the max and min row numbers is even
    const isEvenRowDiff = (maxRow - Math.min(...rowNumbersSet)) % 2 === 0

    // Determine the direction of the task
    const isDirectionUp = taskSettings.direction === 'up'

    const res = {
        '00': false, //yes yes
        '01': true, //yes yes
        '10': true, //yes yes
        '11': false, //yes yes
    }[`${isEvenRowCount ? 1 : 0}${isEvenRowDiff ? 1 : 0}`]

    const result = isDirectionUp ? res : !res

    let begin: [number, number][] = []
    let end: [number, number][] = []

    if (result) {
        end = field.beds
            .filter((bed) => bed.properties.row_number === maxRow)
            .sort((a, b) => b.properties.row_order - a.properties.row_order)[0].geometry.coordinates
    } else {
        end = field.beds
            .filter((bed) => bed.properties.row_number === maxRow && bed.properties.row_order === 1)[0]
            .geometry.coordinates.slice()
            .reverse()
    }
    if (isDirectionUp) {
        begin = field.beds.filter((bed) => bed.properties.row_number === minRow && bed.properties.row_order === 1)[0]
            .geometry.coordinates
    } else {
        begin = field.beds
            .filter((bed) => bed.properties.row_number === minRow)
            .sort((a, b) => b.properties.row_order - a.properties.row_order)[0]
            .geometry.coordinates.slice()
            .reverse()
    }

    if (taskSettings.repeat) {
        end = [...begin].reverse() // Create new array, then reverse
    }
    return {
        begin: begin.map((coords) => ({ lat: coords[1], lng: coords[0] })),
        end: end.map((coords) => ({ lat: coords[1], lng: coords[0] })),
    }
}

const InfoWindowContent = ({ item }: { item: any }) => (
    <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        <SpaIcon sx={{ color: item.strokeColor }} />
        <Divider
            sx={{
                mr: 1,
                ml: 1,
                borderColor: 'gray',
            }}
            orientation='vertical'
            variant='middle'
            flexItem
        />
        <ListItemText
            primary={<span style={{ color: 'black' }}>{`${item.cultivar ?? item.name} 📏 ${item.size}`}</span>}
            secondary={item.cultivar ? <span style={{ color: 'gray' }}>{`${item.name}`}</span> : null}
        />
    </Stack>
)
function BackendTaskOnMap({ drawIcons }: { drawIcons: boolean }) {
    const map = useRecoilValue(mapAtom)
    const alerts = useAlertActions()
    const task_checks = useRef(new google.maps.Data())
    const task = useRecoilValue(taskAtom)
    const startArrow = useRef<{ begin?: any; end?: any; pup?: any }>({})

    useEffect(() => {
        task_checks.current = new google.maps.Data()
        startArrow.current = {
            begin: createArrow(map),
            end: createFlag(map),
            pup: createPUPMarker(map, null, false)[0],
        }
    }, [map])

    useEffect(() => {
        const lines = []
        const cleanup = () => {
            removeFeaturesFromMap(task_checks.current)
            task_checks.current.setMap(null)
            startArrow.current.begin.setMap(null)
            startArrow.current.end.map = null
            startArrow.current.pup.map = null
            lines.forEach((line) => line.setMap(null))
            removeFeaturesFromMap(task_checks.current)
            task_checks.current.setMap(null)
        }
        if (!task) {
            cleanup()
        }
        if (task) {
            startArrow.current.begin.setMap(null)
            removeFeaturesFromMap(task_checks.current)

            let previousName: string | null = null
            let line: google.maps.LatLng[] = []

            task.json_path.features.forEach((feature) => {
                if (feature.name != previousName && line.length) {
                    lines.push(createTaskLine(line, map, previousName))
                    previousName = feature.name
                    line = []
                }
                feature.geometry.coordinates.forEach((v) => {
                    line.push(new window.google.maps.LatLng(v[1], v[0]))
                })
            })
            lines.push(createTaskLine(line, map, previousName))

            if (drawIcons) {
                lines[0].icons.push({
                    icon: arrowSymbol(),
                    offset: '0%',
                })
                const endPath = lines[lines.length - 1].getPath()
                if (!task.pick_up_point) {
                    startArrow.current.end.position = new google.maps.LatLng(endPath.getAt(endPath.getLength() - 1))
                } else {
                    startArrow.current.pup.map = map
                    startArrow.current.pup.position = new google.maps.LatLng(endPath.getAt(endPath.getLength() - 1))
                }
            }

            if (task.checks.filter((c) => !c.success).length) {
                alerts.warning('Task contains boundary crosses')
            }

            task_checks.current.addGeoJson({
                type: 'FeatureCollection',
                features: task.checks,
            })
            task_checks.current.setMap(map)
            addStyleFromGeoJSON(task_checks.current)
        }
        return () => {
            cleanup()
        }
    }, [task])
    return null
}

export function CropTask() {
    const location = useLocation()
    const taskCreateScreen = location.pathname.includes('create-task') || location.pathname.includes('view/')
    const map = useRecoilValue(mapAtom)
    const drawSettings = useRecoilValue(drawSettingsAtom)
    const taskSettings = useRecoilValue(taskSettingsState)
    const mp = useRef({ crop_id: null, data: new google.maps.Data() })
    const startArrow = useRef({
        begin: createArrow(map),
        end: createFlag(map),
    })

    const bedIDsSelector = useBedIDsSelector()
    const selectedBedIDs = useRecoilValue(selectedBedIDsAtom)
    const field = useRecoilValue(h2lFieldJsonAtom)
    const task = useRecoilValue(taskAtom)
    const [infoWindowData, setInfoWindowData] = useState({
        position: null,
        content: null,
    })

    useEffect(() => {
        // Handle Highlighting
        mp.current.data.forEach((feature) => {
            if (selectedBedIDs.has(feature.getId())) {
                mp.current.data.overrideStyle(feature, {
                    strokeWeight: config.drawing.tulip_bed.HIGHLIGHT_STROKE_WEIGHT,
                    strokeOpacity: config.drawing.tulip_bed.HIGHLIGHT_STROKE_OPACITY,
                })
            } else {
                mp.current.data.overrideStyle(feature, {
                    strokeWeight: config.drawing.tulip_bed.DEFAULT_STROKE_WEIGHT,
                    strokeOpacity: config.drawing.tulip_bed.DEFAULT_STROKE_OPACITY,
                })
            }
        })

        // Handle BeginEnd Indicators
        // Show indicators when in task creation mode of machines view or field view
        // Dont show when clicking on beds on the machines view
        if (selectedBedIDs.size && field) {
            if (taskCreateScreen) {
                const filteredBeds = field.beds.filter((bed) => selectedBedIDs.has(bed.id) && !bed.properties.skip)

                if (filteredBeds.length) {
                    // No filtered beds if everything is skipped
                    const { begin, end } = calculateBeginAndEndArrows(field, filteredBeds, taskSettings)
                    startArrow.current.begin.setPath(begin)
                    startArrow.current.begin.setMap(map)
                    startArrow.current.end.position = end[1]
                    startArrow.current.end.map = map
                }
            }
        }
        return () => {
            startArrow.current.begin.setMap(null)
            startArrow.current.end.map = null
        }
    }, [selectedBedIDs, taskSettings])

    const clickBeds = useCallback(
        function (event) {
            // checking for name is for not selecting the boundary
            // id == bed_id
            const bedId = event.feature.getId()
            if (event.feature.getProperty('name') && bedId) {
                // Only in machine view and not on create task
                if (location.pathname.includes('create-task')) {
                    const item = {
                        cultivar: event.feature.getProperty('cultivar'),
                        size: event.feature.getProperty('size'),
                        name: event.feature.getProperty('name'),
                        group_id: event.feature.getProperty('group_id'),
                        strokeColor: event.feature.getProperty('strokeColor'),
                    }

                    if (selectedBedIDs.has(bedId)) {
                        // Clear if click on the same group
                        bedIDsSelector.resetSelectedBedIDs()
                        setInfoWindowData({
                            position: null,
                            content: null,
                        })
                    } else {
                        // Create info window
                        setInfoWindowData({
                            position: event.latLng,
                            content: <InfoWindowContent item={item} />,
                        })

                        // Find all beds in the same group and highlight them
                        const bedsInGroup = new Array<number>()
                        mp.current.data.forEach((feature) => {
                            if (feature.getProperty('group_id') === event.feature.getProperty('group_id')) {
                                bedsInGroup.push(feature.getId() as number)
                            }
                        })
                        bedIDsSelector.replaceMany(bedsInGroup)
                    }
                } else {
                    bedIDsSelector.toggleSelected(bedId)
                }
            }
        },
        [map, selectedBedIDs],
    )
    useEffect(() => {
        // Add a click listener for beds
        window.google.maps.event.clearListeners(mp.current.data, 'click')
        mp.current.data.addListener('click', clickBeds)

        // Add a click listener on the map to clear selection when clicking outside
        // Only for machine view and not on create task
        if (map && location.pathname.includes('create-task')) {
            map.addListener('click', (event) => {
                if (!event.feature) {
                    bedIDsSelector.resetSelectedBedIDs()
                    setInfoWindowData({
                        position: null,
                        content: null,
                    })
                }
            })
        }

        return () => {
            // Clear listeners when the component unmounts
            window.google.maps.event.clearListeners(mp.current.data, 'click')
        }
    }, [clickBeds])

    useEffect(() => {
        if (field) {
            removeFeaturesFromMap(mp.current.data)
            addFeaturesToMap(mp.current.data, field, drawSettings.plantedArea)
            mp.current.data.setMap(map)
            zoomMapLayer(map, mp.current.data)
            mp.current.crop_id = field.crop_id
            map.setHeading(field.heading + 90)
        } else {
            removeFeaturesFromMap(mp.current.data)
            mp.current.data.setMap(null)
        }

        return () => {
            removeFeaturesFromMap(mp.current.data)
            mp.current.data.setMap(null)
        }
    }, [field, drawSettings])

    useEffect(() => {
        if (task && task.method === 'planted-area' && field) {
            mp.current.data.addGeoJson(field.planted_area)
        }
    }, [task, field])

    return (
        <>
            {infoWindowData.position && location.pathname.includes('create-task') && (
                <InfoWindow
                    position={infoWindowData.position}
                    options={{ headerDisabled: true }}
                    onCloseClick={() => setInfoWindowData({ position: null, content: '' })}
                >
                    <div style={{}}>{infoWindowData.content}</div>
                </InfoWindow>
            )}
            <BackendTaskOnMap drawIcons={!taskCreateScreen} />
        </>
    )
}
