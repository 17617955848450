import React from 'react'
import { useRecoilState } from 'recoil'
import { taskSettingsState } from '../_state'
import { ModalBox, RangeSlider } from '.'
import { Button, Typography, Stack } from '@mui/material'
import SecurityIcon from '@mui/icons-material/Security'
import { useTranslation } from 'react-i18next'

export const RadiusSlider = () => {
    const { t } = useTranslation(['fields'])
    const [modalOpen, setModalOpen] = React.useState(false)
    const [taskSettings, setTaskSettings] = useRecoilState(taskSettingsState)

    return (
        <>
            <Button
                startIcon={<SecurityIcon />}
                variant='contained'
                onClick={() => setModalOpen(true)}
                sx={{
                    mr: 0.5,
                    textTransform: 'none',
                    p: 1,
                }}
                size={'large'}
                fullWidth
            >
                <Stack>
                    <Typography>{t('fields:RadiusSlider.btnText')}</Typography>
                    <Stack direction='row' justifyContent='center'>
                        {taskSettings.radius}m
                    </Stack>
                </Stack>
            </Button>
            <ModalBox
                title={t('fields:RadiusSlider.title')}
                open={modalOpen}
                handleDialogClose={() => setModalOpen(!modalOpen)}
            >
                <RangeSlider
                    value={taskSettings.radius}
                    unit={t('fields:RadiusSlider.meters')}
                    step={0.01}
                    min={0.1}
                    max={4.0}
                    handleChange={(newValue: number) => {
                        setTaskSettings((prev) => ({
                            ...prev,
                            radius: newValue,
                        }))
                    }}
                />
            </ModalBox>
        </>
    )
}
