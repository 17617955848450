import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Box, Drawer, Button, Toolbar, Typography, Divider } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import { useTranslation } from 'react-i18next'
import { Upload, ModalBox } from '../_components'
import { useWindowSize } from '../_helpers'

export { FieldEditorDrawer }

function FieldEditorDrawer({ fieldName }: { fieldName: string }) {
    const { field_id, crop_id } = useParams<Record<string, string | undefined>>()
    const fieldID = field_id ? parseInt(field_id) : NaN
    const cropID = crop_id ? parseInt(crop_id) : NaN

    if (isNaN(fieldID) || isNaN(cropID)) {
        console.error('Invalid field or crop ID')
        return
    }

    const windowSize = useWindowSize()
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation(['fields'])
    const urlAnchor = location.hash.split('&')[0]

    const [addBoundaryDialogOpen, setAddBoundaryDialogOpen] = useState(false)
    const handleAddBoundaryDialogOpen = () => setAddBoundaryDialogOpen(true)
    const handleAddBoundaryDialogClose = () => {
        setAddBoundaryDialogOpen(false)
    }
    const handleOverwriteDialogOpen = () => setOverwriteDialogOpen(true)
    const [overwriteDialogOpen, setOverwriteDialogOpen] = useState(false)
    const handleOverwriteDialogClose = () => setOverwriteDialogOpen(false)

    useEffect(() => {
        if (urlAnchor === '#addboundary') setAddBoundaryDialogOpen(true)
    }, [])

    return (
        <>
            {/* Upload boundary dialog */}
            <ModalBox
                title={t('fields:FieldEditorDrawer.addBoundaryFile')}
                open={addBoundaryDialogOpen}
                //open={urlAnchor === '#addboundary'}
                handleDialogClose={handleAddBoundaryDialogClose}
            >
                <Upload type={'boundary'} overwrite={false} fieldID={fieldID} onClose={handleAddBoundaryDialogClose} />
            </ModalBox>
            {/* Overwrite field dialog */}
            <ModalBox
                title={t('fields:FieldEditorDrawer.overwriteField')}
                open={overwriteDialogOpen}
                handleDialogClose={handleOverwriteDialogClose}
            >
                <Upload type={'field'} overwrite={true} fieldID={fieldID} onClose={handleOverwriteDialogClose} />
            </ModalBox>
            <Box sx={{ position: 'relative' }}>
                <Drawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant='persistent'
                    open={windowSize.width >= 1024}
                >
                    <Toolbar />
                    <Box sx={{ m: 2 }}>
                        <Typography variant='h5' gutterBottom component='div'>
                            {fieldName.split('.')[0]}
                        </Typography>
                        <Divider variant='middle' />
                        <Box sx={{ mb: 1, mt: 1 }}>
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                aria-label='add-boundary'
                                startIcon={<AddIcon />}
                                onClick={handleAddBoundaryDialogOpen}
                            >
                                {t('fields:FieldEditorDrawer.uploadBoundary')}
                            </Button>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                aria-label='overwrite-field'
                                startIcon={<FileUploadIcon />}
                                onClick={handleOverwriteDialogOpen}
                            >
                                {t('fields:FieldEditorDrawer.overwriteField')}
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                aria-label='create-task'
                                startIcon={<AddRoadIcon />}
                                onClick={() => navigate(`/fields/${fieldID}/crops/${cropID}/view/`)}
                            >
                                {t('fields:FieldEditorDrawer.taskPlanner')}
                            </Button>
                        </Box>
                    </Box>
                    <Divider variant='middle' />
                </Drawer>
            </Box>
        </>
    )
}
