import { useEffect, MouseEvent, useState, Dispatch, SetStateAction } from 'react'
import UserService from '../services/UserService'
import { useFleetState } from '../_helpers'
import { Link, useNavigate } from 'react-router-dom'
import {
    Container,
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    LinearProgress,
    Stack,
    Badge,
} from '@mui/material'
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded'
import MSUAdminPanel from '../msu/MSUAdminPanel'
import { TagSelect } from '../msu/TagSelect'
import { TicketsCollapse } from '../tickets/TicketsCollapse'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { FleetStatusEntry } from '../types'
import { ReactComponent as TicketsIcon } from '../assets/images/tickets.svg'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { SvgIconWrapper } from '../_components/icons/SvgIconWrapper'
import TableSortLabel from '@mui/material/TableSortLabel'
import { AllMachineCameras } from '../cis'
import { TransferStatusDialog } from './transfer/TransferStatusDialog'
import { FullscreenDialog } from '../_components'
import { useTranslation } from 'react-i18next'

const MachineItem = ({
    machine,
    msuClick,
}: {
    machine: FleetStatusEntry
    msuClick: Dispatch<SetStateAction<FleetStatusEntry>>
}) => {
    const { t } = useTranslation(['machines'])
    const navigate = useNavigate()
    const [ticketsOpen, setTicketsOpen] = useState(false)
    let isDisabled = false
    if (machine?.has_access === false) {
        isDisabled = true
    }

    const handleRowClick = (event: MouseEvent, machine: FleetStatusEntry) => {
        if (isDisabled) return
        if (event.target instanceof Element) {
            if (event.target.tagName === 'A') {
                if (UserService.hasRole(['admin'])) {
                    navigate(`/m/${machine.name}/support`)
                } else {
                    navigate(`/m/${machine.name}/drive`)
                }
            } else if (
                event.target.tagName === 'TH' ||
                event.target.tagName === 'P' ||
                event.target.tagName === 'DIV'
            ) {
                navigate(`/m/${machine.name}/drive`)
            }
        }
    }

    const LinkButton = () => {
        const handleClick = (event) => {
            if (isDisabled) {
                event.preventDefault()
            }
        }
        if (machine.status !== 'Offline') {
            if (UserService.hasRole(['admin'])) {
                return (
                    <Link to={`/m/${machine.name}/support`}>
                        <Button variant='contained' color={machine.status === 'Error' ? 'error' : 'success'}>
                            {machine.status === 'Error'
                                ? t('machines:List.error')
                                : t(`machines:List.${machine.status.toLowerCase()}`)}
                        </Button>
                    </Link>
                )
            } else {
                return (
                    <Link to={`/m/${machine.name}/drive`} onClick={(e) => handleClick(e)}>
                        <Button variant='contained' color={machine.status === 'Error' ? 'error' : 'success'}>
                            {machine.status === 'Error' ? t('machines:List.error') : machine.status}
                        </Button>
                    </Link>
                )
            }
        } else {
            return (
                <Link to={`/m/${machine.name}/drive`} onClick={(e) => handleClick(e)}>
                    <Button variant='contained' disabled={true}>
                        {t('machines:List.offline')}
                    </Button>
                </Link>
            )
        }
    }

    return (
        <>
            <TableRow
                hover
                onClick={(event) => handleRowClick(event, machine)}
                key={machine.name}
                sx={{
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    '& > *': { borderBottom: 'unset' },
                    opacity: isDisabled ? 0.5 : 1,
                }}
            >
                <TableCell
                    component='th'
                    scope='row'
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    <Box sx={{ mr: 1 }}>
                        {machine.name}
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography variant='body2' color='textSecondary'>
                                {machine.company}
                            </Typography>
                            {machine?.owner_company && (
                                <TransferStatusDialog
                                    machineName={machine.name}
                                    companyName={machine.company}
                                    ownerCompany={machine.owner_company}
                                />
                            )}
                        </Stack>
                    </Box>
                </TableCell>
                <TableCell align='right'>
                    <Stack direction='row' alignItems='center'>
                        <LinkButton />
                        {UserService.hasRole(['admin']) && (
                            <IconButton
                                size='small'
                                onClick={() => {
                                    if (!isDisabled && UserService.hasRole(['admin'])) msuClick(machine)
                                }}
                            >
                                <SystemUpdateAltRoundedIcon sx={{ color: machine?.msu && 'orange' }} />
                            </IconButton>
                        )}
                        {UserService.hasRole(['admin', 'manufacturer']) &&
                            machine?.issues &&
                            machine?.issues.count > 0 && (
                                <IconButton
                                    size='small'
                                    color={
                                        machine?.issues.assignees?.includes(UserService.getUsername())
                                            ? 'error'
                                            : 'default'
                                    }
                                    onClick={() => setTicketsOpen(!ticketsOpen)}
                                >
                                    <Badge
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                color: 'white',
                                                backgroundColor: machine?.issues.blocking ? 'red' : 'gray',
                                            },
                                        }}
                                        badgeContent={machine?.issues.count}
                                    >
                                        <SvgIconWrapper>
                                            <TicketsIcon />
                                        </SvgIconWrapper>
                                    </Badge>
                                </IconButton>
                            )}
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
                <TableCell sx={{ pt: 0, pb: 0, pl: 2, pr: 2 }} colSpan={6}>
                    {ticketsOpen && <TicketsCollapse machineName={machine.name} />}
                </TableCell>
            </TableRow>
        </>
    )
}

const TargetVersionSelect = () => {
    const [tagSelectOpen, setTagSelectOpen] = useState(false)
    const handleClose = () => {
        setTagSelectOpen(false)
    }
    return (
        <>
            <IconButton sx={{ color: 'gray' }} onClick={() => setTagSelectOpen(true)}>
                <SystemUpdateAltRoundedIcon />
            </IconButton>
            {tagSelectOpen && (
                <FullscreenDialog
                    toolbarTitle='Machine Software Updates Admin Panel'
                    toolbarStartIcon={
                        <IconButton color='inherit' onClick={handleClose} aria-label='back'>
                            <ArrowBack />
                        </IconButton>
                    }
                    dialogContent={<TagSelect handleClose={() => setTagSelectOpen(false)} />}
                    handleClose={handleClose}
                />
            )}
        </>
    )
}
const statusOrder = ['Error', 'Treating', 'Online', 'Offline']
const sortMachinesByStatus = (machines: FleetStatusEntry[], direction: 'asc' | 'desc'): FleetStatusEntry[] => {
    return machines.sort((a, b) => {
        const comparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
        return direction === 'asc' ? comparison : -comparison
    })
}

const sortMachinesByName = (machines: FleetStatusEntry[], direction: 'asc' | 'desc'): FleetStatusEntry[] => {
    return machines.sort((a, b) => {
        const comparison = a['name'].localeCompare(b['name'])
        return direction === 'asc' ? comparison : -comparison
    })
}

const sort = (
    fleetState: Record<string, FleetStatusEntry>,
    field: 'name' | 'status',
    direction: 'asc' | 'desc',
): FleetStatusEntry[] => {
    let machinesOn: FleetStatusEntry[] = []
    let machinesOff: FleetStatusEntry[] = []

    Object.keys(fleetState).forEach((e) => {
        const machineInfo = {
            name: e,
            company: fleetState[e].company,
            status: fleetState[e].status,
            msu: fleetState[e]?.msu,
            issues: fleetState[e]?.issues,
            owner_company: fleetState[e]?.owner_company,
            has_access: fleetState[e]?.has_access,
        }

        if (fleetState[e].status !== 'Offline') {
            machinesOn.push(machineInfo)
        } else {
            machinesOff.push(machineInfo)
        }
    })

    let comacMachinesOn = machinesOn.filter((machine) => ['Comac', 'H2L Robotics'].includes(machine.company))
    const comacMachinesOff = machinesOff.filter((machine) => ['Comac', 'H2L Robotics'].includes(machine.company))
    machinesOn = machinesOn.filter((machine) => !['Comac', 'H2L Robotics'].includes(machine.company))
    machinesOff = machinesOff.filter((machine) => !['Comac', 'H2L Robotics'].includes(machine.company))

    let sortedMachines = [...machinesOn, ...comacMachinesOn, ...machinesOff, ...comacMachinesOff]

    if (field === 'status') {
        comacMachinesOn = sortMachinesByStatus(comacMachinesOn, direction)
        machinesOn = sortMachinesByStatus(machinesOn, direction)
        sortedMachines = [...machinesOn, ...comacMachinesOn, ...machinesOff, ...comacMachinesOff]
    } else if (field === 'name') {
        sortedMachines = sortMachinesByName(sortedMachines, direction)
    }

    return sortedMachines
}

export const List = () => {
    // console.log('[MachinesList] render')
    const { t } = useTranslation(['machines'])
    const { fleetState } = useFleetState()
    const [msu, setMSU] = useState<null | FleetStatusEntry>(null)
    const [allMachineCamerasOpen, setAllMachineCamerasOpen] = useState(false)
    const [sortConfig, setSortConfig] = useState<{ field: 'name' | 'status'; direction: 'asc' | 'desc' }>({
        field: 'status',
        direction: 'asc',
    })

    const handleOpenAllCameras = () => setAllMachineCamerasOpen(true)
    const handleCloseAllMachineCameras = () => setAllMachineCamerasOpen(false)

    const handleSort = (field: 'name' | 'status'): void => {
        const direction = sortConfig.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        setSortConfig({ field, direction })
    }

    const sortedMachines = sort(fleetState, sortConfig.field, sortConfig.direction)

    const treating = sortedMachines.filter((m: FleetStatusEntry) => m.status === 'Treating').length
    const online = sortedMachines.filter((m: FleetStatusEntry) => m.status === 'Online').length
    const offline = sortedMachines.filter((m: FleetStatusEntry) => m.status === 'Offline').length
    // const error = sortedMachines.filter((m: FleetStatusEntry) => m.status === 'Error').length  // UNCOMMENT after new implementation
    const status = UserService.hasRole(['admin'])
        ? `${t('machines:List.status')} - 🌱${treating} 📡${online} 🚫${offline} `
        : 'Status'

    if (!fleetState) return null
    return (
        <Container sx={{ height: '100%' }}>
            {allMachineCamerasOpen ? (
                <AllMachineCameras handleClose={handleCloseAllMachineCameras} />
            ) : (
                <>
                    {msu && <MSUAdminPanel machine={msu.name} msuClick={setMSU} />}
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='h4' gutterBottom>
                            {t('machines:List.title')}
                        </Typography>

                        <Stack direction={'row'}>
                            {UserService.hasRole(['admin']) && (
                                <Box alignSelf={'center'}>
                                    <TargetVersionSelect />
                                    {UserService.hasRole(['admin']) && (
                                        <IconButton onClick={handleOpenAllCameras}>
                                            <CameraAltIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                    <Paper elevation={3}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                direction={sortConfig.direction}
                                                active={sortConfig.field == 'name'}
                                                onClick={() => handleSort('name')}
                                            >
                                                {t('machines:List.name')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                direction={sortConfig.direction}
                                                active={sortConfig.field == 'status'}
                                                onClick={() => handleSort('status')}
                                            >
                                                {status}
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedMachines.map((machine) => (
                                        <MachineItem key={machine.name} machine={machine} msuClick={setMSU} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {sortedMachines.length === 0 && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color='success' />
                            </Box>
                        )}
                    </Paper>
                </>
            )}
        </Container>
    )
}
export default List
