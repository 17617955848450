import { useState, MouseEvent } from 'react'
import UserService from '../services/UserService'
import { useQueryClient } from '@tanstack/react-query'
import { Link, useResolvedPath, useNavigate } from 'react-router-dom'
import { useFieldActions } from '../_actions'
import { ConfirmDialog, RenameDialog, FullscreenDialog } from '.'
import DownloadIcon from '@mui/icons-material/Download'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AbcIcon from '@mui/icons-material/Abc'
import InfoIcon from '@mui/icons-material/Info'
import CropRotateIcon from '@mui/icons-material/CropRotate'
import DirectionsIcon from '@mui/icons-material/Directions'
import { useFieldFunctions, handleOpenDirections } from '../_helpers'
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'

export { FieldMenu }

const FieldInfoModal = ({ fieldID, handleClose }: { fieldID: number; handleClose: () => void }) => {
    const { t } = useTranslation(['fields'])
    const grafanaUrl = `https://grafana.h2lselector.com/d-solo/2xccOSISk/field-information?var-field_id=${fieldID}&orgId=1&panelId=2`

    return (
        <FullscreenDialog
            toolbarTitle={t('fields:FieldInfoModal.info')}
            dialogContent={
                <iframe
                    width='100%'
                    height='100%'
                    src={grafanaUrl}
                    title={t('fields:FieldInfoModal.info')}
                    frameBorder='0'
                ></iframe>
            }
            dialogContentStyle={{ p: 0, overflow: 'hidden' }}
        />
    )
}

function FieldMenu({
    fieldID,
    cropID,
    fieldName,
    fieldLat,
    fieldLon,
}: {
    fieldID: number
    cropID: number
    fieldName: string
    fieldLat?: number
    fieldLon?: number
}) {
    const url = useResolvedPath('').pathname
    const navigate = useNavigate()
    const fieldFunctions = useFieldFunctions()
    const [fieldInfoDialogOpen, setFieldInfoDialogOpen] = useState(false)
    const [renameDialogOpen, setRenameDialogOpen] = useState<number | null>(null)
    const [discardDialogOpen, setDiscardDialogOpen] = useState(false)
    const { t } = useTranslation(['fields'])
    const discardDialogData = {
        dialogType: 'delete-field',
        title: t('fields:FieldMenu.deleteField'),
        message: t('fields:FieldMenu.deleteConfirm', { fieldName: fieldName }),
    }
    const queryClient = useQueryClient()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const fieldActions = useFieldActions()

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleRename = async (fieldID: number, newName: string) => {
        await fieldActions.update(fieldID, newName)
        queryClient.invalidateQueries(['fields'])
        handleClose()
    }

    const handleDelete = async () => {
        await fieldActions._delete(fieldID)
        queryClient.invalidateQueries(['fields'])
        handleClose()
        if (url.includes('view')) {
            navigate('/fields')
        }
    }
    const options = [
        url === '/fields' && (
            <MenuItem
                key={`rename-${fieldID}`}
                onClick={() => {
                    setRenameDialogOpen(fieldID)
                }}
            >
                <ListItemIcon>
                    <AbcIcon />
                </ListItemIcon>
                <ListItemText>{t('fields:FieldMenu.rename')}</ListItemText>
            </MenuItem>
        ),
        <MenuItem
            key={`edit-${fieldID}`}
            component={Link}
            to={`/fields/${fieldID}/crops/${cropID}/edit/`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            disabled={!cropID}
        >
            <ListItemIcon>
                <EditIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('fields:FieldMenu.edit')}</ListItemText>
        </MenuItem>,
        UserService.hasRole(['dev']) && (
            <MenuItem
                key={`reparse-${fieldID}`}
                onClick={() => {
                    fieldFunctions.reparse(fieldID)
                    handleClose()
                }}
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <ListItemIcon>
                    <CropRotateIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('fields:FieldMenu.reparse')}</ListItemText>
            </MenuItem>
        ),
        UserService.hasRole(['admin']) && (
            <MenuItem
                key={`download-${fieldID}`}
                onClick={() => {
                    fieldActions.getFiles(fieldID)
                    handleClose()
                }}
            >
                <ListItemIcon>
                    <DownloadIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('fields:FieldMenu.download')}</ListItemText>
            </MenuItem>
        ),
        UserService.hasRole(['admin']) && (
            <MenuItem
                key={`field-info-${fieldID}`}
                onClick={() => {
                    setFieldInfoDialogOpen(true)
                }}
            >
                <ListItemIcon>
                    <InfoIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('fields:FieldMenu.info')}</ListItemText>
            </MenuItem>
        ),
        <MenuItem
            key={`delete-${fieldID}`}
            onClick={() => {
                setDiscardDialogOpen(true)
            }}
        >
            <ListItemIcon>
                <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t('fields:FieldMenu.delete')}</ListItemText>
        </MenuItem>,
        fieldLat && fieldLon && (
            <MenuItem key={`directions-${fieldID}`} onClick={() => handleOpenDirections(fieldLat, fieldLon)}>
                <ListItemIcon>
                    <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>{t('fields:FieldMenu.directions')}</ListItemText>
            </MenuItem>
        ),
    ]

    return (
        <div>
            <IconButton
                size='small'
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
                sx={{
                    pointerEvents: 'auto',
                    opacity: 1,
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={handleClose}
            >
                {options.map((option) => option)}
            </Menu>
            {renameDialogOpen && (
                <RenameDialog
                    entryID={renameDialogOpen}
                    entry={fieldName}
                    validationRegex={/^[a-zA-Z\s\d-_.]*$/} // Allow only upper and lower case letters, spaces, and dashes
                    dialogText={t('fields:FieldMenu.renameDialogText')}
                    helperText={t('fields:FieldMenu.renameDialogHelper')}
                    handleClose={() => setRenameDialogOpen(null)}
                    handleRename={handleRename}
                />
            )}
            {fieldInfoDialogOpen && (
                <FieldInfoModal fieldID={fieldID} handleClose={() => setFieldInfoDialogOpen(false)} />
            )}
            <ConfirmDialog
                title={t('fields:FieldMenu.deleteField')}
                open={discardDialogOpen}
                setOpen={setDiscardDialogOpen}
                onConfirm={handleDelete}
                type={discardDialogData}
            >
                {discardDialogData['message']}
            </ConfirmDialog>
        </div>
    )
}
