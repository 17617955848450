import { useTranslation } from 'react-i18next'
import { useState, useEffect, ReactNode } from 'react'
import { useRecoilValue, useResetRecoilState, useRecoilState, useSetRecoilState } from 'recoil'
import {
    machineControlAtom,
    taskListSelectedIndexState,
    taskRequestsAtom,
    subframePositionStartAtom,
    h2lFieldJsonAtom,
    taskQuery,
} from '../_state'
import { Box, Stack, List, Typography, Chip, LinearProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { TaskListItem } from './TaskListItem'
import { useCropActions, useTaskActions, useTaskRequestActions, useAlertActions, useFieldActions } from '../_actions'
import { H2LFieldJson, TaskListItem as TaskListItemType } from '../types'
import { useNavigate } from 'react-router'

const TaskListHeader = ({ title, closeIcon }: { title: string; closeIcon?: ReactNode }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6' sx={{ mt: 0.5 }}>
            {title}
        </Typography>
        {closeIcon}
    </Box>
)

const TaskListFilter = ({ nearest, toggleNearest }: { nearest: boolean; toggleNearest: (value: boolean) => void }) => {
    const { t } = useTranslation(['tasks'])
    return (
        <Stack direction={'row'} alignItems='center' spacing={1}>
            <Chip
                label={t('tasks:TaskListFilter.nearest')}
                color={nearest ? 'secondary' : 'primary'}
                onClick={() => toggleNearest(true)}
            />
            <Chip
                label={t('tasks:TaskListFilter.all')}
                color={!nearest ? 'secondary' : 'primary'}
                onClick={() => toggleNearest(false)}
            />
        </Stack>
    )
}
const TaskManagerModeSelect = ({
    h2lField,
    handleOnClick,
}: {
    h2lField: H2LFieldJson
    handleOnClick: (string) => void
}) => {
    const { t } = useTranslation(['tasks'])
    return (
        <Chip
            onClick={handleOnClick}
            icon={<AddIcon />}
            sx={{
                zIndex: 110,
            }}
            color='secondary'
            label={
                h2lField
                    ? t('tasks:TaskManagerModeSelect.createNewTask')
                    : t('tasks:TaskManagerModeSelect.noFieldNearby')
            }
            disabled={!h2lField}
        />
    )
}

const TaskListItems = ({
    items,
    selectedIndex,
    setSelectedIndex,
    isLoading,
    taskQloading,
}: {
    items: TaskListItemType[]
    selectedIndex: number
    setSelectedIndex: (index: number) => void
    isLoading: boolean
    taskQloading: boolean
}) => {
    return isLoading ? (
        <LinearProgress sx={{ mt: 1, mb: 1 }} color='secondary' />
    ) : (
        <List>
            {items.map((item, index) => (
                <TaskListItem
                    key={item.id}
                    taskListItem={item}
                    selected={index === selectedIndex}
                    onClick={() => setSelectedIndex(index)}
                    disabled={taskQloading}
                />
            ))}
        </List>
    )
}

const TaskListEmpty = ({ loading, noTasks, noGps }: { loading: boolean; noTasks: boolean; noGps: boolean }) => {
    const { t } = useTranslation(['tasks'])
    if (loading) return null
    if (noGps) return <Typography sx={{ m: 2 }}>{t('tasks:TaskListEmpty.noGps')}</Typography>
    if (noTasks) return <Typography sx={{ m: 2 }}>{t('tasks:TaskListEmpty.noTasksFound')}</Typography>
    return null
}

export const TaskList = ({
    isNearestToggleVisible,
    closeIcon, // gets the closeIcon only for the title to be in the same row as the close icon
}: {
    isNearestToggleVisible: boolean
    closeIcon?: ReactNode
}) => {
    const { t } = useTranslation('tasks')
    const navigate = useNavigate()
    const [nearest, setNearest] = useState(isNearestToggleVisible)
    const [loading, setLoading] = useState(true)
    const h2lField = useRecoilValue(h2lFieldJsonAtom)
    const [taskListItems, setTaskListItems] = useRecoilState(taskRequestsAtom)
    const [selectedIndex, setSelectedIndex] = useRecoilState(taskListSelectedIndexState)
    const resetSelectedIndex = useResetRecoilState(taskListSelectedIndexState)
    const machineControl = useRecoilValue(machineControlAtom)
    const gps = useRecoilValue(subframePositionStartAtom)
    const fieldActions = useFieldActions()
    const alert = useAlertActions()
    const taskRequestActions = useTaskRequestActions()

    const setTask = useSetRecoilState(taskQuery)
    const setH2lFieldJson = useSetRecoilState(h2lFieldJsonAtom)
    const taskActions = useTaskActions()
    const cropActions = useCropActions()
    const taskQ = useRecoilValue(taskQuery)

    useEffect(() => {
        // covers the machine view case
        if (isNearestToggleVisible) {
            if (nearest && gps && gps.lon && gps.lat) {
                setLoading(true)

                // we fetch the field via two api calls to avoid caching of field json based on gps coordinates
                fieldActions
                    .getNearestFieldCropID(gps.lon, gps.lat)
                    .then((cropID) => {
                        return cropActions.getCropByIdWithTulibBeds(cropID.last_crop_id)
                    })
                    .then((h2lField) => {
                        setH2lFieldJson(h2lField)
                        return taskRequestActions.getByCompany(h2lField.field_id)
                    })
                    .then(setTaskListItems)
                    .catch(() => {
                        alert.error(t('tasks:TaskList.errGettingTasks'))
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else if (!nearest) {
                setLoading(true)
                taskRequestActions
                    .getByCompany()
                    .then((res) => {
                        setTaskListItems(res)
                    })
                    .catch((res) => {
                        console.error(res)
                        alert.error(t('tasks:TaskList.errGettingTasks'))
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                // convers the case where there is no gps
                setLoading(false)
            }
            // covers the case where the taskList is displayed inside the field view
        } else if (!isNearestToggleVisible && h2lField) {
            setLoading(true)
            taskRequestActions
                .getByCompany(h2lField.field_id)
                .then(setTaskListItems)
                .catch(() => {
                    alert.error(t('tasks:TaskList.errGettingTasks'))
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        return () => {
            setTaskListItems([])
            resetSelectedIndex()
            //setH2lField(null) //This is required, but good for  now
        }
    }, [nearest, gps])

    let taskId: number | null = null
    if (selectedIndex > -1 && taskListItems && taskListItems[selectedIndex]) {
        taskId = taskListItems[selectedIndex]['task_forward_id']
    } else if (machineControl?.task_id) {
        taskId = machineControl.task_id
    }

    useEffect(() => {
        if (taskId) {
            setTask({ data: null, isLoading: true })
            taskActions
                .getById(taskId)
                .then((res) => {
                    cropActions
                        .getCropByIdWithTulibBeds(res.crop_id)
                        .then(setH2lFieldJson)
                        .then(() => {
                            setTask({ data: res, isLoading: false })
                        })
                })
                .catch((e) => setTask({ data: null, isLoading: false }))
        }
    }, [taskId])

    return (
        <>
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: 'background.paper',
                    pt: 1,
                    pl: 2,
                    pr: 2,
                    pb: 2,
                    borderBottom: '3px solid gray',
                }}
            >
                <TaskListHeader
                    title={
                        nearest && h2lField
                            ? t('tasks:TaskList.tasksField', { fieldName: h2lField.name })
                            : t('tasks:TaskList.tasks')
                    }
                    closeIcon={closeIcon}
                />
                {isNearestToggleVisible && (
                    <Stack direction={'row'} justifyContent='space-between'>
                        <>
                            <TaskListFilter
                                nearest={nearest}
                                toggleNearest={() => {
                                    setLoading(true)
                                    setNearest(!nearest)
                                }}
                            />
                            <TaskManagerModeSelect
                                h2lField={h2lField}
                                handleOnClick={() => navigate(`../create-task?crop-id=${h2lField.crop_id}`)}
                            />
                        </>
                    </Stack>
                )}
            </Box>
            <TaskListItems
                items={taskListItems}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                isLoading={loading}
                taskQloading={taskQ.isLoading}
            />
            <TaskListEmpty
                loading={loading}
                noTasks={!loading && taskQ.isLoading === false && taskListItems.length === 0}
                noGps={!loading && gps === null && isNearestToggleVisible}
            />
        </>
    )
}
