import { CreateFieldResponse, ParsedField, UpdateFieldResponse } from '../types'
import { useFetchWrapper } from '../_helpers'

export { useFieldActions }

function useFieldActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        create,
        update,
        getAll,
        uploadFileOnCreate,
        getNearestFieldCropID,
        getFiles,
        _delete,
    }

    async function create(fieldName: string): Promise<CreateFieldResponse> {
        return await fetchWrapper.post(`${baseUrl}/fields`, { name: fieldName })
    }

    async function update(fieldID: number, fieldName: string): Promise<UpdateFieldResponse> {
        return await fetchWrapper.put(`${baseUrl}/fields/${fieldID}`, { name: fieldName })
    }

    function getAll(companyID?: number) {
        if (companyID) return fetchWrapper.get(`${baseUrl}/fields/crops?company_id=${companyID}`)
        return fetchWrapper.get(`${baseUrl}/fields/crops`)
    }

    function uploadFileOnCreate(fieldID: number, file: File): Promise<{ id: number; boundary: boolean }> {
        const formData = new FormData()
        formData.append('input_file', file)
        return fetchWrapper.post(`${baseUrl}/fields/${fieldID}/crops`, formData)
    }

    function getNearestFieldCropID(lon: number, lat: number): Promise<ParsedField | null> {
        return fetchWrapper.get(`${baseUrl}/fields/nearest?skip=0&lon=${lon}&lat=${lat}`)
    }

    function getFiles(fieldID: number) {
        return fetchWrapper.get(`${baseUrl}/fields/${fieldID}/files`)
    }

    function _delete(fieldID: number) {
        return fetchWrapper.delete(`${baseUrl}/fields/${fieldID}`)
    }
}
