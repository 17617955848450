import React from 'react'
import { useAlertActions } from '../_actions'
import GrowShrinkComponent from './GrowShrinkComponent'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import DrawIcon from '@mui/icons-material/Draw'
import { useTranslation } from 'react-i18next'

interface BoundaryEditProps {
    boundary: google.maps.Data
}

export function BoundaryEdit(props: BoundaryEditProps) {
    const alert = useAlertActions()
    const navigate = useNavigate()
    const { t } = useTranslation(['fields'])

    React.useEffect(() => {
        alert.info(t('fields:BoundaryEdit.info'))
        let hasValidFeature = false
        props.boundary.forEach((f) => {
            hasValidFeature = true

            props.boundary.overrideStyle(f, {
                editable: true,
            })
        })
        if (!hasValidFeature) {
            navigate('../boundary-draw', { replace: true })
            return
        }
        return () => {
            props.boundary.forEach((f) => {
                props.boundary.overrideStyle(f, {
                    editable: false,
                })
            })
        }
    }, [])

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
                position: 'fixed',
                bottom: '75px', // Position above the menu bar
                left: '50%', // Center horizontally
                transform: 'translateX(-50%)', // Offset by half its width to truly center
                border: '2px solid #ccc',
                borderRadius: '10px',
                padding: '2px',
                backgroundColor: '#fff',
                maxWidth: '300px',
                zIndex: 999,
            }}
        >
            <GrowShrinkComponent areaToAdjust={props.boundary} />
            <Button
                onClick={() => navigate('../boundary-draw', { replace: true })}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: '#000',
                    borderLeft: '2px solid #ccc',
                    paddingLeft: '8px',
                }}
            >
                <DrawIcon fontSize='large' />
                <Typography variant='caption'>{t('fields:BoundaryEdit.draw')}</Typography>
            </Button>
        </Box>
    )
}
