import React from 'react'
import { Fab } from '@mui/material'
import { ConfirmDialog } from '../_components'
import { H2LFieldJson } from '../types'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface ArrowBackProps {
    field: H2LFieldJson
}

type DialogData = {
    dialogType: string
    title: string
    message: string
}

export function ArrowBackDiscard(props: ArrowBackProps) {
    const { t } = useTranslation(['fields'])
    const navigate = useNavigate()

    const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false)
    const [discardDialogData, setDiscardDialogData] = React.useState(null)

    const handleOpenDiscardDialog = (dialogData: DialogData) => {
        setDiscardDialogData(dialogData)
        setDiscardDialogOpen(true)
    }

    const onArrowBack = () => {
        handleOpenDiscardDialog({
            dialogType: 'discard-skipped-rows',
            title: t('fields:ArrowBackDiscard.title'),
            message: t('fields:ArrowBackDiscard.message'),
        })
    }

    return (
        <>
            <ConfirmDialog
                title={discardDialogData?.title}
                open={discardDialogOpen}
                setOpen={setDiscardDialogOpen}
                onConfirm={() => navigate(-1)}
                type={discardDialogData}
            >
                {discardDialogData && discardDialogData['message']}
            </ConfirmDialog>
            <Fab
                sx={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 1210,
                    top: '2%',
                    left: '2%',
                }}
                size='medium'
                onClick={onArrowBack}
            >
                <ArrowBack />
            </Fab>
        </>
    )
}
