import * as React from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'

export { RenameDialog }

interface RenameDialogProps {
    entryID: number
    entry: string
    validationRegex: RegExp
    dialogText: string
    helperText: string
    handleRename: (entryID: number, newEntry: string) => void
    handleClose: () => void
}
function RenameDialog({
    entryID,
    entry,
    validationRegex,
    dialogText,
    helperText,
    handleRename,
    handleClose,
}: RenameDialogProps) {
    const [newEntry, setNewEntry] = React.useState(entry)
    const [isValid, setIsValid] = React.useState(true)
    const { t } = useTranslation(['fields'])

    const validateName = (name: string) => {
        const isValidName = validationRegex.test(name)
        setIsValid(isValidName)
        return isValidName
    }

    return (
        <>
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>{t('fields:RenameDialog.title', { field: entry })}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='name'
                        fullWidth
                        variant='standard'
                        color='success'
                        value={newEntry}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewEntry(event.target.value)
                            validateName(event.target.value)
                        }}
                        error={!isValid}
                        helperText={!isValid ? helperText : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={(event) => {
                            event.stopPropagation()
                            handleClose()
                        }}
                    >
                        {t('fields:RenameDialog.cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        color='success'
                        onClick={(event) => {
                            event.stopPropagation()
                            if (validateName(newEntry)) {
                                handleRename(entryID, newEntry)
                                handleClose()
                            }
                        }}
                        disabled={!isValid}
                    >
                        {t('fields:RenameDialog.rename')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
