import { useEffect } from 'react'
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom'
import { ArrowBackDiscard } from './ArrowBackDiscard'
import { Box } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { mapAtom } from '../_state'
import { FieldEditorDrawer } from '.'
import { GMapFieldEditor } from '../gmap'
import { MenuEditorChoice, MobileMenuEditorChoice } from '../gmap/MenuEditorChoice'
import { H2LFieldJson } from '../types'
import { useTranslation } from 'react-i18next'

export default function BoundaryDraw() {
    const { t } = useTranslation(['fields'])
    return (
        <>
            <h3>{t('fields:BoundaryDraw.title')}</h3>
        </>
    )
}

interface OutletContext {
    h2lFieldJson: H2LFieldJson
}

export function CropEditorView() {
    const navigate = useNavigate()
    const location = useLocation()
    const { h2lFieldJson } = useOutletContext<OutletContext>()
    const loc = location.pathname.split('/').filter(Boolean).slice(-1)[0]

    useEffect(() => {
        if (loc === 'edit') {
            if (!h2lFieldJson.boundary) {
                navigate(`${location.pathname}boundary-draw`, { replace: true })
            } else {
                navigate(`${location.pathname}boundary-edit`, { replace: true })
            }
        }
    }, [])

    const map = useRecoilValue(mapAtom)
    const field = JSON.parse(JSON.stringify(h2lFieldJson))
    const render = () => {
        return (
            <>
                <ArrowBackDiscard field={field} />
                <MobileMenuEditorChoice />
                {map && <GMapFieldEditor field={field} map={map} />}
                <Box sx={{ position: 'relative' }}>
                    <FieldEditorDrawer fieldName={field.name} />
                </Box>
            </>
        )
    }
    return render()
}
