import { Stack, Box, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import CropIcon from '@mui/icons-material/Crop'
import FenceIcon from '@mui/icons-material/Fence'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import YardIcon from '@mui/icons-material/Yard'
import { useTranslation } from 'react-i18next'

export function MenuEditorChoice() {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation(['fields'])

    const menuItems = [
        { label: t('fields:MenuEditorChoise.drawNew'), to: 'boundary-draw' },
        { label: t('fields:MenuEditorChoise.editBoundary'), to: 'boundary-edit' },
        { label: t('fields:MenuEditorChoise.editPlantedArea'), to: 'planted-area-edit' },
        { label: t('fields:MenuEditorChoise.skipRows'), to: 'skip-rows' },
        { label: t('fields:MenuEditorChoise.cultivarMatching'), to: 'cultivar-matching' },
    ]

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '2%',
                right: '2%',
            }}
        >
            <Stack
                component='nav'
                aria-label='Device settings'
                sx={{
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    padding: '16px',
                }}
            >
                {menuItems.map((item) => (
                    <Button
                        key={item.to}
                        variant={location.pathname.includes(item.to) ? 'contained' : 'outlined'}
                        color='primary'
                        sx={{ marginBottom: '8px' }}
                        onClick={() => navigate(item.to, { replace: true })}
                    >
                        {item.label}
                    </Button>
                ))}
            </Stack>
        </Box>
    )
}
export function MobileMenuEditorChoice() {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation(['fields'])

    const menuItems = [
        { label: t('fields:MobileMenuEditorChoice.boundary'), to: 'boundary-edit', icon: FenceIcon },
        { label: t('fields:MobileMenuEditorChoice.plantedArea'), to: 'planted-area-edit', icon: CropIcon },
        { label: t('fields:MobileMenuEditorChoice.skipRows'), to: 'skip-rows', icon: PlaylistRemoveIcon },
        { label: t('fields:MobileMenuEditorChoice.cultivars'), to: 'cultivar-matching', icon: YardIcon },
    ]

    return (
        <Stack
            component='nav'
            direction='row'
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: 'background.paper',
                justifyContent: 'space-around',
                borderTop: 1,
                borderColor: 'divider',
                py: 0.2,
                zIndex: 1300, // necessary for it to be drawn on top of drawer
            }}
            aria-label='Editor tools'
        >
            {menuItems.map((item) => {
                const isSelected = location.pathname.includes(item.to.split('-')[0])
                const Icon = item.icon

                return (
                    <Button
                        key={item.to}
                        variant='text'
                        color='inherit'
                        onClick={() => navigate(item.to, { replace: true })}
                        sx={{
                            minWidth: 'auto',
                            px: 1,
                            textTransform: 'none',
                            color: isSelected ? 'black' : 'grey.500',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transition: 'transform 0.2s ease',
                            transform: isSelected ? 'scale(1.1)' : 'scale(1)',
                        }}
                    >
                        <Icon
                            sx={{
                                fontSize: isSelected ? 24 : 20,
                                transition: 'fontSize 0.2s ease',
                            }}
                        />
                        <Box
                            sx={{
                                typography: 'caption',
                                fontSize: isSelected ? '0.8rem' : '0.7rem',
                                fontWeight: isSelected ? 500 : 400,
                                transition: 'all 0.2s ease',
                            }}
                        >
                            {item.label}
                        </Box>
                    </Button>
                )
            })}
        </Stack>
    )
}
