import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Map, RobotIcon } from '../_components'
import { Box, Button } from '@mui/material'
import { CropTask } from '../gmap/CropTask'
import { CultivarsListMobile } from '../_components/mobile/CultivarsListMobile'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useCropActions } from '../_actions'
import { useSetRecoilState, useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil'
import { machineNameAtom, h2lFieldJsonAtom, taskQuery, taskSettingsState, lastRouteLocationState } from '../_state'
import { PickUpPointPin } from '../_components/PickUpPointPin'
import Edit from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'

export const MachineCreateTask = () => {
    const { t } = useTranslation(['machines'])
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const cropID = parseInt(searchParams.get('crop-id'))
    const machineName = useRecoilValue(machineNameAtom)
    const setTask = useSetRecoilState(taskQuery)
    const resetTask = useResetRecoilState(taskQuery)
    const resetTaskSettings = useResetRecoilState(taskSettingsState)
    const setCrop = useSetRecoilState(h2lFieldJsonAtom)
    const [field, setField] = useRecoilState(h2lFieldJsonAtom)
    const setLastRoute = useSetRecoilState(lastRouteLocationState)
    const cropActions = useCropActions()
    const [enabledPickUpPoint, setEnabledPickUpPoint] = useState(false)

    useEffect(() => {
        resetTask()
        cropActions.getCropByIdWithTulibBeds(cropID).then(setField)

        return () => {
            setTask({ data: null, isLoading: false })
            setCrop(null)
            cropActions.resetTulipBeds()
            resetTaskSettings()
        }
    }, [machineName])

    if (!machineName || !field) return null
    const handleNavigate = () => {
        setLastRoute(location.pathname)
        navigate(`/fields/${field.field_id}/crops/${field.crop_id}/edit/boundary-edit`)
    }
    return (
        <>
            <Button
                sx={{
                    mt: 2,
                    ml: 2,
                    zIndex: 1000,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                variant='contained'
                size='medium'
                startIcon={<ArrowBack />}
                onClick={() => navigate(`/m/${machineName}/drive`)}
            >
                {machineName}
            </Button>
            <Button
                sx={{
                    mt: 2,
                    mr: 2,
                    zIndex: 1000,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'white',
                    },
                }}
                variant='contained'
                size='medium'
                startIcon={<Edit />}
                onClick={handleNavigate}
            >
                {t('machines:MachineCreateTask.edit')}
            </Button>
            <Box
                sx={{
                    flex: 'auto',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'row-reverse',
                }}
            >
                <Map>
                    <PickUpPointPin isVisible={enabledPickUpPoint} headland={field.headland} />
                    <RobotIcon />
                    <CropTask />
                </Map>
            </Box>
            <Box sx={{ m: 1, height: '1%', flex: 'auto', position: 'relative', overflow: 'auto' }}>
                <CultivarsListMobile
                    h2lFieldJson={field}
                    enabledPickUpPoint={enabledPickUpPoint}
                    setEnabledPickUpPoint={setEnabledPickUpPoint}
                />
            </Box>
        </>
    )
}
