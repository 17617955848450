import { useEffect } from 'react'
import { IconButton, Box } from '@mui/material'
import { TaskList } from '../../tasks'
import { h2lFieldJsonAtom, taskManagerOpenAtom, taskQuery } from '../../_state'
import CloseIcon from '@mui/icons-material/Close'
import { useRecoilState, useSetRecoilState } from 'recoil'

export const TaskManager = () => {
    // console.log('[TaskManager] Render')
    const setH2lField = useSetRecoilState(h2lFieldJsonAtom)
    const setTask = useSetRecoilState(taskQuery)
    const [taskManagerOpen, setTaskManagerOpen] = useRecoilState(taskManagerOpenAtom)

    useEffect(() => {
        setH2lField(null)
        setTask({ data: null, isLoading: false })
        return () => {
            //setH2lField(null) //This is required, but good for  now
            // setTaskManager('')
        }
    }, [taskManagerOpen])

    const closeIcon = (
        <IconButton onClick={() => setTaskManagerOpen(false)} sx={{ cursor: 'pointer', ml: 1 }} size='large'>
            <CloseIcon />
        </IconButton>
    )

    return (
        <Box sx={{ p: 1 }}>
            <TaskList isNearestToggleVisible={true} closeIcon={closeIcon} />
        </Box>
    )
}
