import { atom } from 'recoil'

const mapAtomInitial: google.maps.Map | null = null
const mapAtom = atom<google.maps.Map | null>({
    key: 'map',
    default: mapAtomInitial,
    dangerouslyAllowMutability: true,
})

const drawSettingsAtom = atom({
    key: 'drawSettings',
    default: { plantedArea: true },
})

export { mapAtom, drawSettingsAtom }
